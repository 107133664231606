<script lang="ts" setup>
import { vMaska } from 'maska/vue';
import type { PropType } from 'vue';
import { computed, ref, toRefs } from '#imports';

const props = defineProps({
  placeholder: {
    type: String,
    default: '',
  },
  error: {
    type: [String, Boolean],
    default: false,
  },
  hasCleanIcon: {
    type: Boolean,
    default: false,
  },
  name: {
    type: String,
    required: true,
  },
  id: {
    type: String,
    default: '',
  },
  mask: {
    type: String,
    default: '',
  },
  type: {
    type: String as PropType<'text' | 'password' | 'number'>,
    default: 'text',
  },
  nonFocusable: {
    type: Boolean,
    default: false,
  },
});

defineEmits(['keyup']);

const { type } = toRefs(props);

const model = defineModel({
  type: [String, Number] as PropType<string | number>,
  required: true,
});

const inputFocused = ref(false);

const inputRef = ref<HTMLInputElement | null>(null);

defineExpose({
  inputRef,
});

const inputType = computed(() => {
  if (type.value === 'number') {
    return 'number';
  }

  if (type.value === 'password') {
    return 'password';
  }

  return 'text';
});

const attrs = computed(() => ({
  tabindex: props.nonFocusable ? '-1' : undefined,
}));

const inputStyles = computed(() => {
  if (type.value === 'password' && model.value) {
    return 'c-green-pure font-[Verdana] tracking-widest';
  }

  return ' placeholder-text-secondary c-text-regular';
});
</script>

<template>
  <div
    class="
      h-10 rounded-md border-1px border-input-stroke-idle hover:border-input-stroke-hover text-input
      flex items-center cursor-text
    "
    :class="[
      inputFocused && !error ? 'border-input-stroke-hover!' : '',
      error ? 'border-input-stroke-error!' : '',
    ]"
    tabindex="-1"
    @click="inputRef?.focus()"
  >
    <div
      v-if="$slots.prependedIcon"
      class="pl-4 pr-2 h-full flex items-center justify-center"
    >
      <slot name="prependedIcon" />
    </div>

    <input
      :id="id"
      ref="inputRef"
      v-model="model"
      v-maska="mask"
      v-bind="attrs"
      :name="name"
      :type="inputType"
      :placeholder="placeholder"
      class="outline-none! w-full"
      :class="[
        $slots.prependedIcon ? 'pl-1' : 'pl-4',
        $slots.actionButton ? '' : 'pr-4',
        inputStyles,
      ]"
      @focus="inputFocused = true"
      @blur="inputFocused = false"
      @keyup="$emit('keyup', $event)"
    >

    <div
      v-if="$slots.actionButton"
      class="mr-1.25 ml-1.5"
    >
      <slot name="actionButton" />
    </div>
    <button
      v-if="!$slots.actionButton && hasCleanIcon && model"
      class="
        mr-2 ml-1.5 w-7.5 h-7.5 flex items-center justify-center c-icons-primary
        hover:bg-green-pure/12 hover:c-green-pure transition-colors
      "
      @click.stop="model = ''"
    >
      <Icon name="ic:round-close" class="w-6 h-6" />
    </button>
  </div>
</template>

<style lang="scss">
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
</style>
